<template>
  <TransitionRoot as="template" :show="openModal">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="
        openModal = false;
        $emit('on:close');
      "
      :open="openModal"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-4
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              px-4
              pt-5
              pb-4
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle sm:p-6
            "
          >
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                type="button"
                class="
                  bg-white
                  rounded-md
                  text-gray-400
                  hover:text-gray-500
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-teal-500
                "
                @click="
                  openModal = false;
                  $emit('on:close');
                "
              >
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>

            <div v-if="!showConversor">
              <div class="sm:flex sm:items-start">
                <div class="mt-3 text-left sm:mt-0 sm:ml-4 sm:text-left">
                  <DialogTitle
                    as="h3"
                    class="text-lg leading-6 font-medium text-gray-900"
                  >
                    {{ boxTitle }}

                  </DialogTitle>
                  <div class="mt-4 flex items-center">

                      <p class="text-left font-medium text-gray-600" v-if="hasFunds && !authorized">You have enough USDC in your wallet. <br /> You may proceed to authorize the transfer.</p>
                      <p class="text-left font-medium text-gray-600" v-if="!hasFunds">You don’t have USDC in your wallet <br /> please swap by clicking the button below or <a href="https://www.coinbase.com/usdc" target="_blank">GET SOME</a></p>
                      
                      <div v-if="hasFunds && authorized">
                        <p ><b class="text-bold">Minimum Price</b></p>

                        <div class="mt-1 flex items-center md:block lg:flex">
                          <div class="mx-1 text-sm font-medium text-gray-500">
                            <USDC color="#A0A4B1" size="5" />
                          </div>
                          <div
                            class="
                              flex
                              items-baseline
                              text-base
                              font-opensans font-md
                              text-black
                            "
                          >
                            {{ minValue }}
                            
                          </div>
                        </div>

                      </div>

                    </div>
                  <div v-if="hasFunds && authorized">

                    <label
                      class="
                        block
                        text-sm
                        font-medium
                        text-gray-700
                        mt-6
                      "
                      >Price</label
                    >
                    <div
                      class="
                        mt-2
                        grid grid-cols-1
                        gap-y-6
                        sm:grid-cols-1 sm:gap-x-8
                      "
                    >
                      
                      
                      <div>
                        <div class="mt-1 flex rounded-md shadow-sm">
                          <span
                            class="
                              inline-flex
                              items-center
                              px-3
                              rounded-l-md
                              border border-r-0 border-gray-300
                              bg-gray-50
                              text-gray-500
                              sm:text-sm
                            "
                          >
                            <USDC color="#000000" size="5" />
                          </span>
                          <input
                            type="number"
                            name="usd"
                            :step="inputStep"
                            v-model="bidTotal"
                            class="
                              flex-1
                              min-w-0
                              block
                              w-full
                              px-3
                              py-2
                              rounded-none rounded-r-md
                              bg-gray-200
                              sm:text-sm
                              border-gray-300
                            "
                            placeholder="0"
                          />
                          
                        </div>
                        <p
                            v-if="bidError"
                            class="mt-2 text-sm text-red-600"
                            id="bid-error"
                          >
                            The bid cannot be smaller then the minimum price.
                          </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mt-5 sm:mt-4 flex justify-end flex-wrap border-t pt-4"
              >
                
                <button
                  type="button"
                  class="
                    mt-3
                    sm:mx-3 sm:w-max
                    w-full
                    inline-flex
                    justify-center
                    rounded-md
                    shadow-sm
                    px-4
                    py-2
                    text-base
                    font-medium
                    text-white
                    focus:outline-none
                    sm:mt-0 sm:w-auto sm:text-sm
                    bg-teal-400
                    hover:bg-teal-500
                  "
                  @click="placeBid"
                  v-if="authorized"
                >
                  PLACE BID
                </button>

                <button
                  type="button"
                  class="
                    mt-3
                    sm:mx-3 sm:w-max
                    w-full
                    inline-flex
                    justify-center
                    rounded-md
                    shadow-sm
                    px-4
                    py-2
                    text-base
                    font-medium
                    text-white
                    focus:outline-none
                    sm:mt-0 sm:w-auto sm:text-sm
                    bg-teal-400
                    hover:bg-teal-500
                  "
                  @click="setAllowance"
                  v-if="!authorized && hasFunds"
                >
                  PRE-AUTHORIZE BID
                </button>

                <button
                  type="button"
                  class="
                    mt-3
                    sm:mx-3 sm:w-max
                    w-full
                    inline-flex
                    justify-center
                    rounded-md
                    shadow-sm
                    px-4
                    py-2
                    text-base
                    font-medium
                    text-white
                    focus:outline-none
                    sm:mt-0 sm:w-auto sm:text-sm
                    bg-teal-400
                    hover:bg-teal-500
                  "
                  @click="conversor"
                  v-if="!authorized && !hasFunds"
                >
                  CONVERT TO USDC
                </button>
              </div>
             
            </div>
            <div v-else>
              <div class="sm:flex flex-col sm:items-between">
                <div class="mt-3 text-left w-full sm:text-left">
                  <DialogTitle
                    as="h3"
                    class="text-lg leading-6 font-medium text-gray-900"
                  >
                    Convert ETH to USDC
                  </DialogTitle>
                  <iframe
                    src="https://app.uniswap.org/#/swap?use=v1?outputCurrency=0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2"
                    height="660px"
                    class="w-full"
                    style="
                      margin-top: 24px;
                      border: 0;
                      display: block;
                      border-radius: 10px;
                    "
                    id="myId"
                  />
                </div>
                <div
                  class="mt-5 sm:mt-4 flex justify-end flex-wrap border-t pt-4"
                >
                  
                  
                  <Button @on:submit="showConversor = false" btn-style="outlined" customClass="mr-2" name="CANCEL" />

                  <Button @on:submit="checkCurrentBalance" name="CHECK BALANCE" />
                
                </div>

               
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { onMounted, ref, toRefs, watch } from "vue";
import {
  Dialog,
  DialogOverlay,
  TransitionChild,
  TransitionRoot,
  DialogTitle,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";
import Axios from "axios";
import {bid, tokenAllowance, checkBalance} from '../../blockchain/index';
import USDC from '../Shared/USDC.vue';
import { useStore } from 'vuex';
import Button from '../Layouts/Button.vue';

export default {
  components: {
    Dialog,
    DialogTitle,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XIcon,
    USDC,
    Button
  },
  emits: ["on:bid"],
  props: ["open_modal", "value", "step", "auctionRef"],
  setup(props, { emit }) {
    let { open_modal, value, step, auctionRef } = toRefs(props);
    const openModal = ref(false);
    const showConversor = ref(false);
    const bidTotal = ref(0);
    const usd = ref(0);
    const conversorVal = ref(0);
    const minValue = ref(0);
    const inputStep = ref(0);
    const bidError = ref(false);
    const authorized = ref(false);
    const boxTitle = ref('Authorize USDC');
    const hasFunds = ref(false);
    const usdcBalance = ref(0);

    const store = useStore();

    onMounted(async function () {
      const request = await Axios.get(
        "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USDC&api_key=0aec04c83ee62053aac51c4c380d7ac28faf2224a683e948de42f3b10182b9b6"
      );

      console.log(props.value);

      minValue.value = props.value;
      bidTotal.value = props.value;
      usd.value = request.data.USD * bidTotal.value;
      conversorVal.value = request.data.USD;
      inputStep.value = step;

      usdcBalance.value = await checkBalance();


    });

    watch(open_modal, function (val) {
      openModal.value = val;
      inputStep.value = step;
    })

    watch(step, function (val) {
      inputStep.value = val;
    });

    watch(step, function(val){
      inputStep.value = val 
    })

    watch(value, (val) => {
      bidTotal.value = val;
      usd.value = conversorVal.value * bidTotal.value;
      minValue.value = val;
      hasFunds.value = usdcBalance.value >= val;
    });

    watch(bidTotal, (val) => {
      if (val < minValue.value) {
        bidError.value = true;
      } else {
        bidError.value = false;
      }
    })

    const conversor = () => {
      showConversor.value = true;
    };

    const checkCurrentBalance = async () => {
      usdcBalance.value = await checkBalance();
      console.log(usdcBalance.value);
      showConversor.value = false;
      hasFunds.value = usdcBalance.value >= minValue.value;
    }

    const handleChange = () => {
      usd.value = bidTotal.value * conversorVal.value;
    };

    const setAllowance = async () => {
      store.dispatch('NotificationStore/TOGGLE_LOADING');

      if (bidTotal.value >= minValue.value) {
        try {
          const ERCReceipt = await tokenAllowance(bidTotal.value);
          console.log(ERCReceipt);
          
          store.dispatch('NotificationStore/TOGGLE_LOADING');

          authorized.value = true;
        } catch (error) {
          openModal.value = false;
          store.dispatch('NotificationStore/TOGGLE_LOADING');
          store.dispatch("NotificationStore/setMessage", {
            message: "You canceled Authorization",
            type: "error",
          });
        }
      }
    };


    const placeBid = async () => {
      if (bidTotal.value >= minValue.value) {
        store.dispatch('NotificationStore/TOGGLE_LOADING');

        const receipt = await bid(auctionRef.value, bidTotal.value);
        openModal.value = false;
        store.dispatch('NotificationStore/TOGGLE_LOADING');
        emit("on:bid", {
          amount: bidTotal.value || 0,
          receipt: receipt
        });
      }
    };

    return {
      openModal,
      usd,
      conversor,
      handleChange,
      conversorVal,
      placeBid,
      showConversor,
      bidTotal,
      minValue,
      inputStep,
      bidError,
      authorized,
      setAllowance,
      boxTitle,
      hasFunds,
      checkCurrentBalance
    };
  },
};
</script>

<template>
  <LightBox
    :images="imageUrl"
    :visible="visibleLightBox"
    @on:close="visibleLightBox = false"
    v-if="imageUrl"
  />
  <div class="detail bg-gray-100 font-inter">
    <div class="relative lg:py-12 py-4 lg:pt-12 lg:pb-4">
      <div class="relative">
        <div
          class="
            text-center
            mx-auto
            max-w-md
            px-4
            sm:max-w-3xl sm:px-6
            lg:px-8 lg:max-w-7xl
          "
        >
          <p
            class="
              mt-2
              text-4xl
              font-medium font-inter
              leading-10
              text-black
              tracking-tight
              sm:text-4xl
            "
          >
            {{ nft.title }}
          </p>
        </div>
        <div
          :class="[
            'lg:mt-12 mt-6 mx-auto px-2 sm:px-6 lg:px-10',
            'flex justify-center lg:max-w-5xl',
          ]"
        >
          <div
            class="
              flex flex-col
              items-center
              justify-center
              h-max
              object-contain
              shadow-lg
              overflow-hidden
            "
          >
            <div class="flex-shrink-0">
              <img
                class="h-full w-full object-contain img-max-height"
                :src="imageUrl"
                alt=""
                @click="showModal"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="
        flex
        md:flex-nowrap
        flex-wrap
        md:px-6
        lg:px-12
        px-2
        pb-3
        mt-10
        justify-between
        items-center
      "
    >
      <div class="w-full md:pl-2">
        <div class="flex justify-between">

          <h2 class=" pl-0 text-2xl font-medium m-0 text-black sm:text-2xl">
            {{nft.sub_title}}
          </h2>
          <div
            class="
              flex flex-nowrap
              md:px-2
              px-0
              justify-between
              md:w-1/4
              lg:w-1/4
              w-full
            "
          >
            <a class="w-full flex" v-if="nft.qrViews">
              <span class="sr-only">Views</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-teal-400 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                />
              </svg>
              <span
                class="w-max text-base leading-6 font-normal font-inter text-black"
                >{{nft.qrViews}} QR views</span
              >
            </a>
            <div class="w-full justify-end flex">
              <a class="text-teal-400 mr-2  cursor-pointer" @click="refresh">
                <span class="sr-only">Refresh</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                  />
                </svg>
              </a>
              <Share />
            </div>
          </div>
        </div>
        <p class="text-sm text-gray-500" v-html="nft.description.replace(/\n/g, '<br>\n')">
        </p>
      </div>
      

      
    </div>

    <div
      class="
        lg:pb-4 lg:pt-2
        sm:py-0 sm:px-6
        lg:px-12
        md:grid
        grid-cols-1
        gap-2
        sm:grid-cols-2
        lg:grid-cols-3
        xl:grid-cols-4
        min-w-full
        flex flex-col-reverse
        justify-start
        items-start
      "
    >
      <div
        class="
          flex flex-wrap
          justify-end
          items-start
          xl:grid xl:grid-cols-3 xl:gap-1 xl:col-span-3
          lg:col-span-2 lg:row-span-1
          gap-2
        "
      >
        <div v-for="card in cards" :key="card.id" :class="card.class">
          <div class="bg-white rounded-lg">
            <div class="min-w-7xl mx-auto py-4 px-4 sm:py-2 sm:px-6 lg:px-4">
              <div class="min-w-3xl mx-auto divide-y-2 divide-gray-200">
                <dl class="space-y-6 divide-y divide-gray-200">
                  <component
                    v-bind:is="card.component"
                    :token_id="nft.blockchainId"
                    :ipfs="nft.ipfs"
                    :auction="auctionref"
                    :auctions="nft.auctions"
                    :update="updateData"
                  ></component>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="md:col-span-1 w-full pb-2 px-0">
        <Spec
          @on:login="login_modal = true"
          :nft="{ ...nft, artistName }"
          @on:info="placebid_note = true"
          :auctionref="auctionref"
          :auction="auction"
          @on:placedBid="placedBid"
        />
      </div>
    </div>

    <!-- stats -->
    <NFTBox @on:open="nft_modal = true" />
  </div>

  <!-- Place Bid note -->
  <TransitionRoot as="template" :show="nft_modal">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="nft_modal = false"
      :open="nft_modal"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-2
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>
        <span
          class="sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              px-4
              pt-5
              pb-4
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle
              lg:max-w-sm
              w-full
              sm:p-6
            "
          >
            <div
              class="
                hidden
                sm:block
                absolute
                top-0
                right-0
                pt-4
                pr-4
                focus:outline-none
              "
            >
              <button
                type="button"
                class="bg-white rounded-md text-gray-400 focus:outline-none"
                @click="nft_modal = false"
              >
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="sm:flex sm:items-start justify-center">
              <div class="mt-3 text-center sm:mt-0 sm:text-center">
                <DialogTitle
                  as="h3"
                  class="text-lg leading-6 font-medium text-gray-900"
                >
                  Generic
                </DialogTitle>
              </div>
            </div>
            <div class="mt-5 sm:mt-4">Content</div>

            <div class="mt-5 sm:mt-4">
              <button
                type="button"
                class="
                  mt-3
                  w-full
                  inline-flex
                  justify-center
                  rounded-md
                  border border-gray-200
                  shadow-sm
                  px-4
                  py-2
                  bg-white
                  text-base
                  font-medium
                  text-gray-700
                  hover:bg-gray-50 hover:text-gray-900
                  focus:outline-none
                  sm:mt-0 sm:w-full sm:text-sm
                "
                @click="nft_modal = false"
              >
                Ok
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Place Bid note -->
  <TransitionRoot as="template" :show="placebid_note">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="placebid_note = false"
      :open="placebid_note"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-2
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              px-4
              pt-5
              pb-4
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle
              lg:max-w-sm
              w-full
              sm:p-6
            "
          >
            <div
              class="
                hidden
                sm:block
                absolute
                top-0
                right-0
                pt-4
                pr-4
                focus:outline-none
              "
            >
              <button
                type="button"
                class="bg-white rounded-md text-gray-400 focus:outline-none"
                @click="placebid_note = false"
              >
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="sm:flex sm:items-start justify-center">
              <div class="mt-3 text-center sm:mt-0 sm:text-center">
                <DialogTitle
                  as="h3"
                  class="text-lg leading-6 font-medium text-gray-900"
                >
                  Placing a Bid
                </DialogTitle>
              </div>
            </div>
            <div class="mt-5 sm:mt-4">
              <p>
                The Highest Bidder will win the NFT at the end of the auction. 
                Auctions are placed in USDC which are a 1:1 equivalent with USD.
              </p>
              <!-- <p class="mt-5">
                USDC allows you to place multiple bids with the same funds
                simultaneously. As soon as one of your bids is won, other
                auctions will be automatically cancel if using the same funds.
              </p> -->
            </div>

            <div class="mt-5 sm:mt-4">
              <button
                type="button"
                class="
                  mt-3
                  w-full
                  inline-flex
                  justify-center
                  rounded-md
                  border border-gray-200
                  shadow-sm
                  px-4
                  py-2
                  bg-white
                  text-base
                  font-medium
                  text-gray-700
                  hover:bg-gray-50 hover:text-gray-900
                  focus:outline-none
                  sm:mt-0 sm:w-full sm:text-sm
                "
                @click="placebid_note = false"
              >
                Ok
              </button>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>

  <!-- Convert ETH -->
  <TransitionRoot as="template" :show="convert_eth">
    <Dialog
      as="div"
      static
      class="fixed z-10 inset-0 overflow-y-auto"
      @close="convert_eth = false"
      :open="convert_eth"
    >
      <div
        class="
          flex
          items-end
          justify-center
          min-h-screen
          pt-4
          px-2
          pb-20
          text-center
          sm:block sm:p-0
        "
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            class="
              inline-block
              align-bottom
              bg-white
              rounded-lg
              px-4
              pt-5
              pb-4
              text-left
              overflow-hidden
              shadow-xl
              transform
              transition-all
              sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6
            "
          >
            <div class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
              <button
                type="button"
                class="
                  bg-white
                  rounded-md
                  text-gray-400
                  hover:text-gray-500
                  focus:outline-none
                  focus:ring-2
                  focus:ring-offset-2
                  focus:ring-white
                "
                @click="convert_eth = false"
              >
                <span class="sr-only">Close</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left">
                <DialogTitle
                  as="h3"
                  class="text-lg leading-6 font-medium text-gray-900"
                >
                  Place Bid
                </DialogTitle>
              </div>
            </div>
            <div class="mt-5 sm:mt-4">
              <a
                href="#"
                @click.prevent="
                  convert_eth = false;
                  place_bid = true;
                "
              >
                <img src="/images/convert_eth.png" alt="" />
              </a>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add the lines below */
@layer utilities {
  @variants responsive {
    .card {
      width: 400px;
    }

    .img-max-height {
      max-height: 564px;
    }
  }
}
</style>

<script>
// @ is an alias to /src
import { computed, onMounted, ref } from "vue";
import { XIcon } from "@heroicons/vue/outline";
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import Blockchain from "@/components/Drawers/Blockchain_data";
import Trading from "@/components/Drawers/Trading_view";
import Spec from "@/components/Spec";
import Bids from "@/components/Drawers/Bids";
import NFTBox from "@/components/NFTBox";
import LightBox from "../../components/Layouts/LightBox.vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import { db, storage } from "../../firebase/firebase";
import Share from "../../components/Modals/Share.vue";

const cards = [
  {
    id: 1,
    component: <Blockchain  />,
    class:
      "xl:w-full lg:w-6/12 w-full pb-2 self-start xl:order-1 lg:order-2 order-3",
  },
  {
    id: 2,
    component: <Trading />,
    class: "xl:w-full lg:w-6/12 w-full pb-2 self-start order-2",
  },
  {
    id: 3,
    component: <Bids />,
    class: "xl:w-full lg:w-6/12 w-full pb-2 xl:order-3 lg:order-1 order-1",
  },
];

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    XIcon,
    Spec,
    Blockchain,
    Trading,
    Bids,
    NFTBox,
    LightBox,
    Share,
  },
  setup() {
    const open = ref(false);
    const placebid_note = ref(false);
    const nft_modal = ref(false);
    const login_modal = ref(false);
    const login = ref(false);
    const phone_login1 = ref(false);
    const phone_login2 = ref(false);
    const phone_login3 = ref(false);
    const place_bid = ref(false);
    const convert_eth = ref(false);
    const visibleLightBox = ref(false);

    const route = useRoute();
    const store = useStore();
    const collectionRef = route.params.collection;
    const nftRef = route.params.ref;

    const bid = ref(0);

    const nft = ref({
      title: "",
      collection: "",
      sub_title: "",
      dimension: "",
      medium: "",
      description: "",
      imageUrl: "",
      link: "",
      qrCodeImage: "",
      ipfs: "",
      metadataIpfs: "",
      isMinted: false,
      blockchainId: 0,
      blockChainOwner: "",
      auctions: [],
    });
    const auction = computed(() => store.state.auctionStore.auction);
    const auctionref = ref();

    const artistName = ref("");

    const imageUrl = ref("");
    const updateData = ref(false);

    const getFullImageURL = async (item) => {
      var storageRef = storage.ref();
      imageUrl.value = await storageRef.child(item).getDownloadURL();
    };

    const getData = async () => {
      await store.dispatch("collection/loadCollection", collectionRef);
      artistName.value = store.getters["collection/getName"];

      const collection = await db.collection("nfts").doc(nftRef).get();
      nft.value = collection.data();

      if (nft.value.auctions) {
        auctionref.value = nft.value.auctions[nft.value.auctions.length - 1];
        await store.dispatch("auctionStore/getAuction", auctionref.value);
      }

      getFullImageURL(nft.value.imageUrl);
    };

    onMounted(async () => {
      await getData();
    });

    const showModal = () => {
      visibleLightBox.value = true;
    };

    const refresh = () => {
      return location.reload();
    };
    
    const placedBid = () => {
      updateData.value = true;
      setTimeout(() => updateData.value = false, 1000);
    }

    return {
      nft,
      cards,
      artistName,
      auction,
      auctionref,
      open,
      placebid_note,
      nft_modal,
      login_modal,
      login,
      phone_login1,
      phone_login2,
      phone_login3,
      place_bid,
      convert_eth,
      showModal,
      visibleLightBox,
      refresh,
      imageUrl,
      bid,
      placedBid,
      updateData
    };
  },
};
</script>
